<template>
  <!-- 赛事介绍 -->
    <div>
      <Footer ></Footer>
      <div class="content">
          <img src="./challenge/innovation.jpg" alt="">
      </div>
      <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {
      }
    },
    methods:{
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  @import './challenge.scss'
  </style>